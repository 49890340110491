<template>
    <ModuleWrapper 
        :bodyPadding="false"
        :pageTitle="pageTitle">
        <!--<template v-if="!isMobile" v-slot:h_left>
            <PageFilter
                :model="pageModel"
                :key="page_name"
                size="large"
                :page_name="page_name" />
        </template>-->
        <a-spin :spinning="loading" class="w-full report_spin">
            <div class="report_dashboard">
                <div class="pb-3">
                    <DSelect
                        v-model="organization"
                        size="large"
                        apiUrl="/users/my_organizations/"
                        class="w-full"
                        firstSelected
                        oneSelect
                        showPlaceholder
                        infinity
                        labelKey="name"
                        placeholder="Выберите организацию"
                        @change="orgChange" />
                </div>
                <div class="report_dashboard__grid"> <!--grid gap-4 grid-cols-1 2xl:grid-cols-2-->
                    <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <ReportTask :statData="statData" />
                        <ReportProject :statData="statProjectData" />
                    </div>
                    <!--<ReportOrg :statData="statData" />-->
                </div>
            </div>
        </a-spin>
    </ModuleWrapper>
</template>

<script>
import ModuleWrapper from '@/components/ModuleWrapper/index.vue'
//import PageFilter from '@/components/PageFilter'
import ReportTask from './ReportTask.vue'
import ReportProject from './ReportProject.vue'
//import ReportOrg from './ReportOrg.vue'
import DSelect from '@apps/DrawerSelect/Select.vue'
export default {
    components: {
        ModuleWrapper,
        //PageFilter,
        ReportTask,
        ReportProject,
        //ReportOrg,
        DSelect
    },
    props: {
        page_name: {
            type: String,
            default: 'page_list_projects.WorkgroupModel'
        },
        pageModel: {
            type: String,
            default: 'workgroups.WorkgroupModel'
        },
    },
    computed: {
        pageTitle() {
            return this.$route?.meta?.title || ''
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            loading: false,
            organization: null,
            statData: null,
            statProjectData: null
        }
    },
    created() {
        
    },
    watch: {
        organization() {
            this.getReportsTask()
            this.getReportsProjects()
        }
    },
    methods: {
        orgChange() {
            this.getReportsTask()
            this.getReportsProjects()
        },
        async getReportsTask() {
            try {
                this.loading = true
                const { data } = await this.$http.get('/contractor_reports/contractor_tasks/', {
                    params: {
                        organization: this.organization
                    }
                })
                this.statData = data
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async getReportsProjects() {
            try {
                this.loading = true
                const { data } = await this.$http.get('/contractor_reports/contractor_projects/', {
                    params: {
                        organization: this.organization
                    }
                })
                this.statProjectData = data
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.report_dashboard{
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    &__grid{
        flex-grow: 1;
    }
}
.report_spin{
    height: 100%;
    &::v-deep{
        .ant-spin-container{
            height: 100%;
        }
    }
}
</style>