<template>
    <Reports />
</template>

<script>
import Reports from '@apps/Reports'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Reports
    }
}
</script>